<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-modal
      id="modal-pdf-viewer"
      ref="modalPdfViewer"
      title=""
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer/>
    </b-modal>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Zoeken..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceCollectionListTable"
        class="position-relative"
        :items="fetchInvoiceCollections"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        style="min-height: 200px"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          #{{ data.value }}
        </template>

        <template #cell(timestamp)="data">
          {{ formatDateTime(data.value)}}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="resolveTypeVariant(data.value).variant">
            {{ resolveTypeVariant(data.value).status }}
          </b-badge>
        </template>

        <template #cell(companyID)="data">
          {{ data.item.company.name }}
        </template>

        <template #cell(subAmount)="data">
          &euro; {{parseFloat(data.value).toFixed(2)}}
        </template>

        <template #cell(vatAmount)="data">
          &euro; {{parseFloat(data.value).toFixed(2)}}
        </template>

        <template #cell(totalAmount)="data">
          &euro; {{parseFloat(data.value).toFixed(2)}}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-if="$can('read', 'invoice-collection') && data.item.payURL !== null && (!data.item.status || data.item.status === 10)"
              :id="`pay-url-row-${data.item.id}-invoice`"
              @click="goToURL(data.item.payURL)"
            >
              <feather-icon
                :id="`pay-url-row-${data.item.id}-invoice-icon`"
                class="cursor-pointer"
                icon="CreditCardIcon"
                size="16"
                style="color: #29625b;"
              />
              Factuur betalen
            </b-dropdown-item>
            <b-dropdown-item
              :id="`pdf-row-${data.item.id}-preview`"
              @click="viewDocument(data.item)"
            >
              <feather-icon
                :id="`pdf-row-${data.item.id}-preview-icon`"
                class="cursor-pointer"
                icon="EyeIcon"
                size="16"
                style="color: #29625b;"
              />
              Bekijken
            </b-dropdown-item>
            <b-dropdown-item
              :id="`invoices-collection-row-${data.item.id}-download`"
              :href="`${axiosDefaultConfig.backendEndPoint}invoicecollections/${data.item.id}/pdf?token=${data.item.pdfToken}`"
              target="_blank"
            >
              <feather-icon
                :id="`invoices-collection-row-${data.item.id}-download-icon`"
                class="cursor-pointer"
                icon="DownloadIcon"
                size="16"
              />
              Downloaden
            </b-dropdown-item>
            <b-dropdown-item
              :id="`invoices-collection-row-${data.item.id}-delete`"
              target="_blank"
              @click="confirmRemoveInvoiceCollection(data.item.id)"
            >
              <feather-icon
                :id="`invoices-collection-row-${data.item.id}-delete-icon`"
                class="cursor-pointer"
                icon="XCircleIcon"
                size="16"
              />
              Verwijderen
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalInvoiceCollections"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BOverlay,
  BPagination,
  BRow,
  BTable, VBToggle,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { required } from '@core/utils/validations/validations'
import { formatDateTime } from '@core/utils/utils'
import PdfViewer from '@/views/components/pdf-viewer/PdfViewer.vue'
import useInvoiceCollectionList from '@/views/pages/invoiceCollection/useInvoiceCollectionList'
import formValidation from '@core/comp-functions/forms/form-validation'
import invoiceCollectionStoreModule from '@/views/pages/invoiceCollection/invoiceCollectionStoreModule'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    BOverlay,
    PdfViewer,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  methods: {
    goToURL(url) {
      window.open(url)
    },
    viewDocument(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}invoicecollections/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
    confirmRemoveInvoiceCollection(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder verzamelfactuur!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-invoice-collection/deleteInvoiceCollection', id)
            .then(() => {
              this.refetchData()
              this.$swal({
                icon: 'success',
                title: 'Verzamelfactuur!',
                text: 'De verzamelfactuur is verwijderd.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Verzamelfactuur',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const INVOICE_COLLECTION_APP_STORE_MODULE_NAME = 'app-invoice-collection'

    // Register module
    if (!store.hasModule(INVOICE_COLLECTION_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_COLLECTION_APP_STORE_MODULE_NAME, invoiceCollectionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_COLLECTION_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_COLLECTION_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalInvoiceCollections,
      dataMeta,
      perPageOptions,
      sortBy,
      refInvoiceCollectionListTable,
      statusFilter,
      resolveTypeVariant,
      statusOptions,
      refetchData,
      showOverlay,
      isSortDirDesc,
      searchQuery,
      fetchInvoiceCollections,
    } = useInvoiceCollectionList()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalInvoiceCollections,
      dataMeta,
      perPageOptions,
      sortBy,
      refInvoiceCollectionListTable,
      statusFilter,
      resolveTypeVariant,
      statusOptions,
      refetchData,
      showOverlay,
      isSortDirDesc,
      searchQuery,
      fetchInvoiceCollections,
      axiosDefaultConfig,
      required,
      refFormObserver,
      getValidationState,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
